import { useCallback, useState } from 'react';

import { getOrganizations } from '@app/adapter/organization-service';
import {
  Organization,
  OrganizationStructureType,
  OrganizationStructureTypeKey,
} from '@app/types/organization';
import { isError } from '@app/utils/error';

export interface FetchOrganizationsOptionProps {
  id?: string | string[];
  isAll?: boolean;
  keyword?: string;
  page?: number;
  pageSize?: number;
  parentId?: string;
  sort?: string;
  status?: string;
  structureType?: OrganizationStructureTypeKey;
}

export const useOrganization = () => {
  const [isLoading, setLoading] = useState(false);

  const fetchOrganizations = useCallback(
    async (options?: FetchOrganizationsOptionProps) => {
      try {
        const { data } = await getOrganizations({
          filter: {
            id: options?.id,
            keyword: options?.keyword,
            parentId: options?.parentId,
            status: options?.status,
            structureType: options?.structureType,
          },
          orderBy: options?.sort,
          page: options?.page,
          pageSize: options?.isAll ? 100 : options?.pageSize,
        });

        if (options?.isAll && data?.['@nextLink']) {
          // TODO: これだとMAX200件までしか取得できない
          const { data: nextData } = await getOrganizations({
            nextLink: data['@nextLink'],
          });
          return { ...data, value: [...data.value, ...nextData.value] };
        }

        return data;
      } catch (error) {
        if (isError(error)) {
          throw error;
        }
      }
    },
    []
  );

  const [parentOrgTotal, setParentOrgTotal] = useState(0);
  const [parentOrgs, setParentOrgs] = useState<Organization[]>([]);
  const fetchParentOrganizations = useCallback(
    async (options?: FetchOrganizationsOptionProps) => {
      try {
        setLoading(true);
        const data = await fetchOrganizations({
          ...options,
          structureType: OrganizationStructureType.PARENT,
        });
        setParentOrgTotal(data?.total || 0);
        setParentOrgs(data?.value || []);
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchOrganizations]
  );

  const [childOrgTotal, setChildOrgTotal] = useState(0);
  const [childOrgs, setChildOrgs] = useState<Organization[]>([]);
  const fetchChildOrganizations = useCallback(
    async (options?: FetchOrganizationsOptionProps) => {
      try {
        setLoading(true);
        const data = await fetchOrganizations(options);
        setChildOrgTotal(data?.total || 0);
        setChildOrgs(data?.value || []);
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchOrganizations]
  );

  return {
    childOrgTotal,
    childOrgs,
    fetchChildOrganizations,
    fetchOrganizations,
    fetchParentOrganizations,
    isLoading,
    parentOrgTotal,
    parentOrgs,
    setChildOrgs,
  };
};
