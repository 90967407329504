import { sortBy } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue, useRecoilState } from 'recoil';

import {
  createProduct,
  updateProduct,
  uploadBlob,
} from '@app/adapter/catalog-service';
import { isLoading } from '@app/domain/app';
import { categoryByActiveSelector } from '@app/domain/catalog';
import {
  ExhibitionEditForm,
  ExhibitionEditFormData,
  ExhibitionEditFormDefaultValues as DefaultValue,
  VariantEditFormKey,
} from '@app/schemas/catalog';
import {
  Product,
  ProductCategory,
  ProductCreate,
  ProductUpdate,
  ProductVariantCreate,
  ProductVariantSku,
  ProductVariantSkuKey,
} from '@app/types/catalog';
import { base64ToBlob } from '@app/utils/base64';
import {
  getPublicationSince,
  getPublicationUntil,
  getVariantsBySku,
} from '@app/utils/catalog';
import { isError } from '@app/utils/error';
import { dateConvertToFormat, isFormatDay } from '@app/utils/format';

export const useProductExhibitionForm = () => {
  const [, setLoadingState] = useRecoilState(isLoading);
  const [organizationId, setOrganizationId] = useState<string>();
  // カテゴリ一覧
  const categories = useRecoilValue(categoryByActiveSelector);
  // フォーム群
  const productForm = useForm<ExhibitionEditFormData>(ExhibitionEditForm);

  const setFormValues = (data: Product) => {
    const customFields = data.customFields || {};
    productForm.reset({
      access: customFields.access || DefaultValue.access,
      additionalInformation:
        data.additionalInformation || DefaultValue.additionalInformation,
      addressLine1: data.addressLine1 || DefaultValue.addressLine1,
      addressLine2: data.addressLine2 || DefaultValue.addressLine2,
      addressLine3: data.addressLine3 || DefaultValue.addressLine3,
      addressLine3HiddenFlag:
        customFields.addressLine3HiddenFlag ||
        DefaultValue.addressLine3HiddenFlag,
      addressLine4: customFields.addressLine4 || DefaultValue.addressLine4,
      category: data.category.name || DefaultValue.category,
      cityId: data.locationIds?.[1] || DefaultValue.cityId,
      closingTime: customFields.closingTime
        ? isFormatDay(customFields.closingTime)
          ? [
              dateConvertToFormat(customFields.closingTime, 'HH') ||
                DefaultValue.closingTime[0],
              dateConvertToFormat(customFields.closingTime, 'mm') ||
                DefaultValue.closingTime[1],
            ]
          : customFields.closingTime.split(':')
        : DefaultValue.closingTime,
      contactAdditional:
        customFields.contactAdditional || DefaultValue.contactAdditional,
      contactAddress:
        customFields.contactAddress || DefaultValue.contactAddress,
      contactPhoneNumber:
        customFields.contactPhoneNumber?.split('-') ||
        DefaultValue.contactPhoneNumber,
      description: data.description || DefaultValue.description,
      features: data.features || DefaultValue.features,
      floorPlans: data?.variants
        ? getVariantsBySku(data.variants, ProductVariantSku.FLOOR_PLAN)
        : DefaultValue.floorPlans,
      mainVisuals: data.images || DefaultValue.mainVisuals,
      name: data.name || DefaultValue.name,
      openingTime:
        customFields.openingTime?.split(':') || DefaultValue.openingTime,
      postalCode: data.postalCode || DefaultValue.postalCode,
      prefectureId: data.locationIds?.[0] || DefaultValue.prefectureId,
      publication: {
        since: data.publication.since || DefaultValue.publication.since,
        status: data.publication.status || DefaultValue.publication.status,
        until: data.publication.until || DefaultValue.publication.until,
      },
      regularHoliday:
        customFields.regularHoliday || DefaultValue.regularHoliday,
      regularHolidayAdditional:
        customFields.regularHolidayAdditional ||
        DefaultValue.regularHolidayAdditional,
    });
  };

  const getUpdatePayload = async (): Promise<ProductUpdate> => {
    if (!organizationId) {
      throw new Error('所属IDが指定されていません');
    }

    const categoryId =
      categories.find((c) => c.name === ProductCategory.EXHIBITION)?.id || '';
    const values = productForm.getValues();

    const mainVisuals = await Promise.all(
      values.mainVisuals.map(async (value, index) => {
        let id = value.id;
        if (!id) {
          const blob = await base64ToBlob(value.url);
          const attachment = await uploadBlob(organizationId, blob);
          id = attachment.id;
          productForm.setValue(`mainVisuals.${index}.id`, attachment.id);
        }
        return { id, key: index };
      })
    );

    const variants: ProductVariantCreate[] = [];
    variants.push(
      ...(await getVariantPayloadBySku(
        values.floorPlans,
        ProductVariantSku.FLOOR_PLAN
      ))
    );

    const closingTime =
      values.closingTime[0] && values.closingTime[1]
        ? `${values.closingTime[0]}:${values.closingTime[1]}`
        : '';

    return {
      additionalInformation: values.additionalInformation,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressLine3: values.addressLine3,
      categoryId,
      customFields: {
        access: values.access,
        addressLine3HiddenFlag: values.addressLine3HiddenFlag,
        addressLine4: values.addressLine4,
        category: ProductCategory.EXHIBITION,
        closingTime,
        contactAdditional: values.contactAdditional,
        contactAddress: values.contactAddress,
        contactPhoneNumber: values.contactPhoneNumber.join('-'),
        openingTime: values.openingTime.join(':'),
        regularHoliday: values.regularHoliday,
        regularHolidayAdditional: values.regularHolidayAdditional,
      },
      description: values.description,
      features: values.features
        .filter((f) => f.value !== '')
        .map((f) => {
          return { name: '', value: f.value };
        }),
      imageIds: sortBy(
        mainVisuals.filter((i) => !!i.id),
        'key'
      ).map((i) => i.id),
      locationIds: [values.prefectureId, values.cityId].filter((l) => !!l),
      name: values.name,
      postalCode: values.postalCode,
      publication: {
        since: getPublicationSince(
          values.publication.status,
          values.publication.since || undefined
        ),
        status: values.publication.status,
        until: getPublicationUntil(
          values.publication.status,
          values.publication.until || undefined
        ),
      },
      variants,
    };
  };

  const getVariantPayloadBySku = async (
    variant: VariantEditFormKey[],
    sku: ProductVariantSkuKey
  ): Promise<ProductVariantCreate[]> => {
    if (!organizationId) {
      throw new Error('所属IDが指定されていません');
    }

    const images = await Promise.all(
      variant.map(async (value, index) => {
        if (value.url && !value.imageId) {
          const blob = await base64ToBlob(value.url);
          const attachment = await uploadBlob(organizationId, blob);
          value.imageId = attachment.id;
        }

        return {
          ...value,
          imageIds: value.imageId ? [value.imageId] : [],
          key: index,
        };
      })
    );

    return sortBy(images, 'key').map((value) => {
      return {
        customFields: value.customFields || {},
        description: value.description || '',
        imageIds: value.imageIds || [],
        price: { amount: 0 },
        sku,
        title: value.title || '',
      };
    });
  };

  const sendFormValues = async (id?: string) => {
    if (!organizationId) {
      throw new Error('所属IDが指定されていません');
    }

    try {
      setLoadingState(true);
      let updatedData;
      if (id) {
        const { data } = await updateProduct(id, await getUpdatePayload());
        updatedData = data;
      } else {
        const { data } = await createProduct(
          organizationId,
          (await getUpdatePayload()) as ProductCreate
        );
        updatedData = data;
      }
      return updatedData;
    } catch (error) {
      if (isError(error)) {
        throw new Error(error.message);
      }
    } finally {
      setLoadingState(false);
    }
  };

  return {
    productForm,
    sendFormValues,
    setFormValues,
    setOrganizationId,
  };
};
