import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ProductCategoryKey } from '@app/types/catalog';
import { Common, EmptyKey } from '@app/types/common';
import { OrderStatus, OrderStatusKey } from '@app/types/order';

/**
 * Order search form
 */
export interface OrderSearchFormData {
  createdAtSince: string;
  createdAtUntil: string;
  sort: string;
  status: OrderStatusKey | EmptyKey;
}
export const OrderSearchFormDefaultValues: OrderSearchFormData = {
  createdAtSince: '',
  createdAtUntil: '',
  sort: 'createdAt desc',
  status: Common.EMPTY,
};
export const OrderSearchForm = {
  defaultValues: OrderSearchFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      createdAtSince: z.string(),
      createdAtUntil: z.string(),
      sort: z.string(),
      status: z.string(),
    })
  ),
} as const;

/**
 * Order CSV download form
 */
export interface OrderCsvDownloadFormKey {
  dateFrom: string;
  dateTo: string;
  organizationId: string;
  productCategory: ProductCategoryKey | EmptyKey;
  status: OrderStatusKey;
}
export const OrderCsvDownloadFormDefaultValues: OrderCsvDownloadFormKey = {
  dateFrom: '',
  dateTo: '',
  organizationId: '',
  productCategory: Common.EMPTY,
  status: OrderStatus.PENDING,
};
export const OrderCsvDownloadForm = {
  defaultValues: OrderCsvDownloadFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      dateFrom: z.string().min(1),
      dateTo: z.string().min(1),
      organizationId: z.string().min(1),
      productCategory: z.string(),
      status: z.string(),
    })
  ),
} as const;
