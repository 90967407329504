import { LoadingButton } from '@mui/lab';
import {
  Button,
  Paper,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactElement, useState } from 'react';

import { isError } from '@app/utils/error';

export const FILE_MAX_SIZE = 5 * 1024 * 1024; //5MB

interface ImageUploadProps {
  disabled?: boolean;
  fileInfo?: string;
  loading?: boolean;
  multiple?: boolean;
  multipleMax?: number;
  onError?: (error: Error) => void;
  onFileUpload: (url: string[]) => void;
  sx?: SxProps<Theme>;
}

export function ImageUpload({
  disabled = false,
  fileInfo,
  loading = false,
  multiple = false,
  multipleMax,
  onError,
  onFileUpload,
  sx,
}: ImageUploadProps): ReactElement {
  const theme = useTheme();
  const [isDragActive, setIsDragActive] = useState(false);

  const handleChange = async function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    try {
      if (e.target.files) {
        const objectUrls = await Promise.all(
          Array.from(e.target.files).map(async (file) => {
            return await handleFileFactory(file);
          })
        );
        onFileUpload(objectUrls);
      }
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
        onError?.(e);
      }
    }
  };

  const handleDeleteFactory = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onFileUpload([]);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragActive(false);
  };

  const handleDrop = async function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragActive(false);
    if (disabled) return;
    try {
      if (e.dataTransfer.files) {
        const objectUrls = await Promise.all(
          Array.from(e.dataTransfer.files).map(async (file) => {
            return await handleFileFactory(file);
          })
        );
        onFileUpload(objectUrls);
      }
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
        onError?.(e);
      }
    }
  };

  const handleFileFactory = (file: File | null): string => {
    if (!file) {
      throw new Error('ファイルが見つかりません');
    }
    if (file.size > FILE_MAX_SIZE) {
      throw new Error('ファイルサイズ制限を超えています');
    }
    if (!file.type.startsWith('image/')) {
      throw new Error('画像ファイルを指定して下さい');
    }
    return URL.createObjectURL(file);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: theme.customPalette.gray3,
        borderStyle: 'dashed',
        display: 'flex',
        flexDirection: 'column',
        height: 1,
        justifyContent: 'center',
        p: 3,
        ...sx,
      }}
    >
      {fileInfo ? (
        <Stack spacing={1.5} alignItems="center">
          <img src={fileInfo} alt="sample" style={{ width: '100%' }} />
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleDeleteFactory}
            >
              <Typography variant="body3">削除する</Typography>
            </Button>
            <LoadingButton
              component="label"
              variant="outlined"
              color="secondary"
              size="small"
              disabled={disabled}
              loading={loading}
            >
              <Typography variant="body3">ファイルを選択する</Typography>
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleChange}
              />
            </LoadingButton>
          </Stack>
        </Stack>
      ) : (
        <Stack
          spacing={3}
          alignItems="center"
          py={3}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          sx={{ opacity: isDragActive ? 0.5 : 1 }}
        >
          <Typography variant="body2" fontWeight={700}>
            ここにファイルをドラッグ&ドロップ
          </Typography>
          <LoadingButton
            component="label"
            color="primary"
            variant="contained"
            size="small"
            disabled={disabled}
            loading={loading}
          >
            <Typography variant="body3">ファイルを選択する</Typography>
            <input
              type="file"
              accept="image/*"
              multiple={multiple}
              hidden
              onChange={handleChange}
            />
          </LoadingButton>
          <Typography
            variant="body3"
            textAlign="center"
            color={theme.customPalette.gray8}
          >
            <div>アップロード可能なファイルサイズの上限は1つあたり5MB</div>
            {!!multipleMax && (
              <div>同時にアップロードできるのは{multipleMax}ファイルまで</div>
            )}
            <div>推奨の縦横比は横3：縦2</div>
          </Typography>
        </Stack>
      )}
    </Paper>
  );
}
