import { ProductCategory, ProductCategoryKey } from '@app/types/catalog';
import { OrderType, OrderTypeKey } from '@app/types/order';

/**
 * orderTypeでProductカテゴリを取得する
 */
export function getCategoryValueByOrderType(
  type?: OrderTypeKey
): ProductCategoryKey | undefined {
  switch (type) {
    case OrderType.EXHIBITION_REQUEST_DOC:
    case OrderType.EXHIBITION_VISITOR_RESERVE:
      return ProductCategory.EXHIBITION;
    case OrderType.PROPERTY_REQUEST_DOC:
    case OrderType.PROPERTY_VISITOR_RESERVE:
      return ProductCategory.PROPERTY;
  }
  return undefined;
}

/**
 * ProductカテゴリでorderTypeを取得する
 */
export function getOrderTypeByProductCategory(
  category?: ProductCategoryKey,
  type?: OrderTypeKey
): OrderTypeKey | undefined {
  if (category && type) {
    switch (type) {
      case OrderType.EXHIBITION_REQUEST_DOC:
        return category === ProductCategory.PROPERTY
          ? OrderType.PROPERTY_REQUEST_DOC
          : type;
      case OrderType.EXHIBITION_VISITOR_RESERVE:
        return category === ProductCategory.PROPERTY
          ? OrderType.PROPERTY_VISITOR_RESERVE
          : type;
      case OrderType.PROPERTY_REQUEST_DOC:
        return category === ProductCategory.EXHIBITION
          ? OrderType.EXHIBITION_REQUEST_DOC
          : type;
      case OrderType.PROPERTY_VISITOR_RESERVE:
        return category === ProductCategory.EXHIBITION
          ? OrderType.EXHIBITION_VISITOR_RESERVE
          : type;
    }
  }
  return undefined;
}
