import {
  Box,
  Button,
  FormHelperText,
  Paper,
  TextField,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import { ReactElement } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { AdditionalButton } from '@app/components/Shared/Button/AdditionalButton';
import { ImageUpload } from '@app/components/Shared/Inputs/ImageUpload';
import { PROPERTY_VARIANT_EQUIPMENT_CATEGORY_VALUES as CATEGORY_VALUES } from '@app/utils/constants';
import { getResizeImageURL } from '@app/utils/fileUpload';

export function EquipmentFormSection(): ReactElement {
  const {
    clearErrors,
    control,
    formState: { errors },
    setError,
    setValue,
    watch,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'equipments',
  });

  return (
    <Stack spacing={3}>
      <Typography variant="h5">設備</Typography>
      {fields.map((field, index) => (
        <Stack key={field.id} spacing={1.5}>
          <Stack direction="row" spacing={1.5} alignItems="flexEnd">
            <Typography variant="h6">{index + 1}</Typography>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => remove(index)}
              sx={{ height: 32 }}
            >
              <Typography variant="body3">クリア</Typography>
            </Button>
          </Stack>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Stack direction="row" spacing={3} useFlexGap>
              <Box flex={1}>
                <ImageUpload
                  fileInfo={watch(`equipments.${index}.url`)}
                  onFileUpload={async (urls) => {
                    const resizeUrl = await getResizeImageURL(
                      urls[0],
                      undefined,
                      undefined,
                      3 / 2
                    );
                    clearErrors(`equipments.${index}`);
                    setValue(`equipments.${index}.imageId`, '');
                    setValue(`equipments.${index}.url`, resizeUrl);
                  }}
                  onError={(e) =>
                    setError(`equipments.${index}`, { message: e.message })
                  }
                />
                {Array.from(
                  new Set([get(errors, `equipments.${index}.message`, '')])
                )
                  .filter((m) => !!m)
                  .map((message, index) => (
                    <FormHelperText key={index} error>
                      {message as string}
                    </FormHelperText>
                  ))}
              </Box>
              <Stack spacing={1.5} flex={1}>
                <FormControl fullWidth>
                  <FormLabel focused={false}>画像カテゴリー</FormLabel>
                  <Controller
                    name={`equipments.${index}.customFields.category`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select {...field} error={!!error} displayEmpty>
                        {Object.keys(CATEGORY_VALUES)
                          .sort((a, b) => a.localeCompare(b))
                          .map((key, index) => (
                            <MenuItem key={index} value={key}>
                              {CATEGORY_VALUES[key]}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel focused={false}>説明タイトル</FormLabel>
                  <Controller
                    name={`equipments.${index}.title`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel focused={false}>説明本文</FormLabel>
                  <Controller
                    name={`equipments.${index}.description`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        multiline
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      ))}
      <AdditionalButton
        label="設備の追加"
        disabled={fields.length >= 15}
        fullWidth
        onClick={() =>
          append({
            customFields: { category: '' },
            description: '',
            id: '',
            imageId: '',
            title: '',
            url: '',
          })
        }
      />
    </Stack>
  );
}
