import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const DEMAND_APP = process.env.REACT_APP_DEMAND_APP;

export const PreviewCategory = {
  EXHIBITIONS: 'exhibitions', //展示場
  PROPERTIES: 'properties', //分譲
} as const;
export type PreviewCategoryKey =
  (typeof PreviewCategory)[keyof typeof PreviewCategory];

export interface PreviewProps {
  category: PreviewCategoryKey;
}
export function Preview({ category }: PreviewProps) {
  const { id } = useParams();
  const urlPath =
    category === PreviewCategory.EXHIBITIONS
      ? 'exhibitions-preview'
      : category === PreviewCategory.PROPERTIES
      ? 'properties-preview'
      : '';

  return (
    <>
      <Box p={2}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => window.close()}
        >
          <Typography>プレビューを閉じる</Typography>
        </Button>
      </Box>
      <iframe
        src={`${DEMAND_APP}/${urlPath}/${id}`}
        title="プレビュー"
        frameBorder="0"
        style={{
          height: '100vh',
          maxWidth: '100%',
          width: '100%',
        }}
      />
    </>
  );
}
