import { EmptyKey } from '@app/types/common';
import { Organization } from '@app/types/organization';

/**
 * Product
 */
export interface Product {
  additionalInformation?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  attributes?: ProductAttribute[];
  category: Category;
  categoryId: string;
  createdAt: string;
  customFields?: ProductCustomFields;
  delFlg: number;
  description?: string;
  dimensions?: string;
  features?: ProductFeature[];
  geoCoordinatesField?: ProductGeoCoordinatesField;
  id: string;
  imageIds?: string[];
  images?: ProductImage[];
  locationIds?: string[];
  locations?: ProductLocation[];
  name: string;
  orderTotalization?: ProductOrderTotalization;
  organization: Organization;
  organizationId: string;
  postalCode?: string;
  publication: ProductPublication;
  tags?: string[];
  updatedAt: string;
  variantNote?: string;
  variants?: ProductVariant[];
}

export interface ProductCustomFields {
  access?: string;
  addressLine3HiddenFlag?: boolean;
  addressLine4?: string;
  areaRatio?: string;
  buildingAreaRangeType?: ProductRangeTypeKey;
  buildingAreaType?: ProductAreaTypeKey;
  buildingConfirmationNumber?: string;
  buildingPermitReasonType?: string;
  buildingStatus?: ProductBuildingStatusKey;
  buildingSystem?: string;
  category?: ProductCategoryKey;
  closingTime?: string;
  companyAddress?: string;
  companyName?: string;
  completionDate?: string;
  completionDateType?: ProductCompletionDateTypeKey;
  completionSeason?: ProductMonthSeasonKey;
  conditionsTransaction?: string;
  conditionsTransactionNote?: string;
  constructionCompany?: string;
  contactAdditional?: string;
  contactAddress?: string;
  contactPhoneNumber?: string;
  deliveryDate?: string;
  deliveryDateType?: ProductDeliveryDateTypeKey;
  deliveryNote?: string;
  deliverySeason?: ProductMonthSeasonKey;
  eventAction?: string;
  eventContent?: string;
  eventDateList?: string[];
  eventDateSince?: string;
  eventDateType?: ProductEventDateTypeKey;
  eventDateUntil?: string;
  eventName?: ProductEventTitleKey;
  eventTimeSince?: string;
  eventTimeUntil?: string;
  facility?: string;
  facilityCost?: string;
  floorPlanRangeType?: ProductRangeTypeKey;
  floorPlanRooms?: number[];
  floorPlanTypeAdditional?: string;
  floorPlanTypeMax?: string;
  floorPlanTypeMin?: string;
  groupName?: string;
  landAreaRangeType?: ProductRangeTypeKey;
  landAreaType?: ProductAreaTypeKey;
  landCategory?: string;
  landLeaseholdCost?: string;
  landLeaseholdDuration?: string;
  landLeaseholdRent?: string;
  landRightType?: ProductLandRightTypeKey;
  legalLimitations?: string[];
  legalOtherLimitationNote?: string;
  legalOtherLimitationType?: string;
  legalOtherLimitations?: string[];
  licenseNumber?: string;
  lotteryDate?: string;
  lotteryDateSeason?: ProductMonthSeasonKey;
  maxBuildingArea?: number;
  maxLandArea?: number;
  maxPrice?: number;
  maxRoadWidth?: string;
  minBuildingArea?: number;
  minLandArea?: number;
  minPrice?: number;
  minRoadWidth?: string;
  mostPriceRangeAdditional?: string;
  mostPriceRangeCount?: string;
  mostPriceRanges?: string[];
  openingTime?: string;
  otherCost?: string;
  previewAdComment?: string;
  priceAdditional?: string;
  priceNote?: string;
  priceRangeType?: ProductRangeTypeKey;
  priceStatus?: ProductPriceStatusKey;
  propertyCategory?: ProductPropertyCategoryKey;
  reformExterior?: ProductReformKey;
  reformInterior?: ProductReformKey;
  regularHoliday?: string;
  regularHolidayAdditional?: string;
  roadNote?: string;
  roadPainting?: string;
  roadWidthRangeType?: ProductRangeTypeKey;
  salesDateSeason?: ProductMonthSeasonKey;
  salesEndDate?: string;
  salesHouseCount?: string;
  salesHouseStatus?: ProductSalesHouseStatusKey;
  salesScheduleComment?: string;
  salesStartDate?: string;
  salesStatus?: ProductSalesStatusKey;
  salesType?: ProductSalesTypeKey;
  totalHouseCount?: string;
  transactionExpirationDate?: string;
  transportMainLine?: string;
  transportMainMean?: ProductTransportMeanKey;
  transportMainNearest?: string;
  transportMainNote?: string;
  transportMainTimeMax?: number;
  transportMainTimeMin?: number;
  transportSub1Line?: string;
  transportSub1Mean?: ProductTransportMeanKey;
  transportSub1Nearest?: string;
  transportSub1Note?: string;
  transportSub1TimeMax?: number;
  transportSub1TimeMin?: number;
  transportSub2Line?: string;
  transportSub2Mean?: ProductTransportMeanKey;
  transportSub2Nearest?: string;
  transportSub2Note?: string;
  transportSub2TimeMax?: number;
  transportSub2TimeMin?: number;
  transportationFlag?: boolean;
  tsuboNotationFlag?: boolean;
  useDistrict?: string[];
}

export interface ProductPublication {
  since?: string;
  status?: ProductStatusKey;
  until?: string;
}
export type ProductPublicationInput = Partial<
  Omit<ProductPublication, 'publishedAt' | 'publishedBy'>
>;

export interface ProductGeoCoordinatesField {
  coordinates: number[];
  type: ProductGeoCoordinatesFieldTypeKey;
}

export interface ProductFeature {
  name: string;
  value: string;
}

export interface ProductImage {
  id?: string;
  type?: string;
  url: string;
}

export interface ProductLocation {
  children?: ProductLocation[];
  code: string;
  createdAt: string;
  id: string;
  name: string;
  parent: string;
  type: LocationTypeKey;
  updatedAt: string;
}

export type ProductAttribute = {
  attributeId: string;
  value: string;
};

export interface ProductOrderTotalization {
  requestDocMonthlyCount?: Record<string, number>;
  visitorReserveMonthlyCount?: Record<string, number>;
}

export type ProductCreate = Omit<
  Product,
  | 'category'
  | 'createdAt'
  | 'delFlg'
  | 'id'
  | 'images'
  | 'locations'
  | 'organization'
  | 'organizationId'
  | 'updatedAt'
  | 'variants'
> & {
  imageIds?: string[];
  locationIds?: string[];
  variants?: ProductVariantCreate[];
};

export type ProductUpdate = Partial<ProductCreate>;

export const ProductStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;
export type ProductStatusKey =
  (typeof ProductStatus)[keyof typeof ProductStatus];

export const ProductGeoCoordinatesFieldType = {
  POINT: 'Point',
} as const;
export type ProductGeoCoordinatesFieldTypeKey =
  (typeof ProductGeoCoordinatesFieldType)[keyof typeof ProductGeoCoordinatesFieldType];

export const SearchConditionAlert = {
  ALL: '',
  ERROR: 'error',
  ERROR_ALERT: 'errorAlert',
} as const;
export type SearchConditionAlertKey =
  (typeof SearchConditionAlert)[keyof typeof SearchConditionAlert];

export const ProductCategory = {
  EXHIBITION: 'exhibition', // 住宅展示場
  PROPERTY: 'property', // 分譲物件
} as const;
export type ProductCategoryKey =
  (typeof ProductCategory)[keyof typeof ProductCategory];

export const ProductPropertyCategory = {
  DETACHED_HOUSE: 'detachedHouse', // 一戸建て
  LAND: 'land', // 土地
} as const;
export type ProductPropertyCategoryKey =
  | (typeof ProductPropertyCategory)[keyof typeof ProductPropertyCategory]
  | EmptyKey;

export const ProductSalesStatus = {
  NEW_BUILDING_CONDO: 'newBuildingCondo', // 新築分譲
  SCHEDULED_SALE: 'scheduledSale', // 販売予定
  SECRET: 'secret', // 掲載止め
  SELLING_STOP: 'sellingStop', // 売り止め
  SOLD_OUT: 'soldOut', // 完売・成約
  USED_CONDO: 'usedCondo', // 分譲中古
} as const;
export type ProductSalesStatusKey =
  | (typeof ProductSalesStatus)[keyof typeof ProductSalesStatus]
  | EmptyKey;

export const ProductSalesType = {
  FCFS: 'fcfs', // 先着順
  LOTTERY: 'lottery', // 登録抽選
  PENDING: 'pending', // 未定
} as const;
export type ProductSalesTypeKey =
  | (typeof ProductSalesType)[keyof typeof ProductSalesType]
  | EmptyKey;

export const ProductLandRightType = {
  FIXED_GROUND: 'fixedGround', // 定期地上
  FIXED_LEASE: 'fixedLease', // 定期賃借
  OLD_LAW_GROUND: 'oldLawGround', // 旧法地上
  OLD_LAW_LEASE: 'oldLawLease', // 旧法賃借
  OWNERSHIP: 'ownership', // 所有権
  REGULAR_GROUND: 'regularGround', // 普通地上
  REGULAR_LEASE: 'regularLease', // 普通賃借
} as const;
export type ProductLandRightTypeKey =
  | (typeof ProductLandRightType)[keyof typeof ProductLandRightType]
  | EmptyKey;

export const ProductAreaType = {
  REGISTRY: 'registry', // 登記
  SURVEY: 'survey', // 実測
} as const;
export type ProductAreaTypeKey =
  | (typeof ProductAreaType)[keyof typeof ProductAreaType]
  | EmptyKey;

export const ProductSalesHouseStatus = {
  PENDING: 'pending', // 未定
  PLAN: 'plan', // 予定・確定
} as const;
export type ProductSalesHouseStatusKey =
  | (typeof ProductSalesHouseStatus)[keyof typeof ProductSalesHouseStatus]
  | EmptyKey;

export const ProductCompletionDateType = {
  COMPLETED: 'completed', // 完成済み
  COMPLETION_PLAN: 'completionPlan', // 完成予定
  CONTRACTED: 'contracted', // 契約後
} as const;
export type ProductCompletionDateTypeKey =
  | (typeof ProductCompletionDateType)[keyof typeof ProductCompletionDateType]
  | EmptyKey;

export const ProductDeliveryDateType = {
  CONSULTATION: 'consultation', // 相談
  CONTRACTED: 'contracted', // 契約後
  IMMEDIATE: 'immediate', // 即引渡可
  SPECIFIC: 'specific', // 指定有
} as const;
export type ProductDeliveryDateTypeKey =
  | (typeof ProductDeliveryDateType)[keyof typeof ProductDeliveryDateType]
  | EmptyKey;

export const ProductBuildingStatus = {
  NEW: 'new', // 新築
  UNOCCUPIED: 'unoccupied', // 未入居
  USED: 'used', // 中古
} as const;
export type ProductBuildingStatusKey =
  | (typeof ProductBuildingStatus)[keyof typeof ProductBuildingStatus]
  | EmptyKey;

export const ProductReform = {
  COMPLETED: 'completed', // 済
  COMPLETION_PLAN: 'completionPlan', // 完了予定
  NONE: 'none', // 無
} as const;
export type ProductReformKey =
  | (typeof ProductReform)[keyof typeof ProductReform]
  | EmptyKey;

export const ProductMonthSeason = {
  BEGIN: 'begin', // 初旬
  EARLY: 'early', // 上旬
  END: 'end', // 末
  LATE: 'late', // 下旬
  MIDDLE: 'middle', // 中旬
} as const;
export type ProductMonthSeasonKey =
  | (typeof ProductMonthSeason)[keyof typeof ProductMonthSeason]
  | EmptyKey;

export const ProductFloorPlanType = {
  DDKK: 'DDKK',
  DK: 'DK',
  DKK: 'DKK',
  K: 'K',
  KK: 'KK',
  LDDKK: 'LDDKK',
  LDK: 'LDK',
  LDKK: 'LDKK',
  LK: 'LK',
  LKK: 'LKK',
  LLDDKK: 'LLDDKK',
  LLDKK: 'LLDKK',
  LLKK: 'LLKK',
  R: 'R',
} as const;
export type ProductFloorPlanTypeKey =
  (typeof ProductFloorPlanType)[keyof typeof ProductFloorPlanType];

export const ProductRangeType = {
  BETWEEN: 'between',
  SEPARATE: 'separate',
} as const;
export type ProductRangeTypeKey =
  | (typeof ProductRangeType)[keyof typeof ProductRangeType]
  | EmptyKey;

export const ProductPriceStatus = {
  PENDING: 'pending', // 未定
  PLAN: 'plan', // 予定・確定
} as const;
export type ProductPriceStatusKey =
  | (typeof ProductPriceStatus)[keyof typeof ProductPriceStatus]
  | EmptyKey;

export const ProductEventTitle = {
  LOCAL_GUIDE: 'localGuide', // 現地案内会
  LOCAL_SALE: 'localSale', // 現地販売会
  LOCAL_TOUR: 'localTour', // 現地見学会
  MODEL_HOUSE: 'modelHouse', // モデルハウス
  MODEL_ROOM: 'modelRoom', // モデルルーム
  OPEN_HOUSE: 'openHouse', // オープンハウス
  OPEN_ROOM: 'openRoom', // オープンルーム
} as const;
export type ProductEventTitleKey =
  | (typeof ProductEventTitle)[keyof typeof ProductEventTitle]
  | EmptyKey;

export const ProductEventDateType = {
  BETWEEN: 'between', // 期間限定
  EVERY_HOLIDAY: 'everyHoliday', // 毎週土日祝
  EVERY_WEEKEND: 'everyWeekend', // 毎週土日
  FIXED_DATE: 'fixedDate', // 日時指定
  IN_PROGRESS: 'inProgress', // 公開中
} as const;
export type ProductEventDateTypeKey =
  | (typeof ProductEventDateType)[keyof typeof ProductEventDateType]
  | EmptyKey;

export const ProductTransportMean = {
  BUS: 'bus',
  CAR: 'car',
  WALK: 'walk',
} as const;
export type ProductTransportMeanKey =
  | (typeof ProductTransportMean)[keyof typeof ProductTransportMean]
  | EmptyKey;

/**
 * Product Variant
 */
export interface ProductVariant {
  createdAt: string;
  customFields?: VariantCustomFields;
  description?: string;
  id: string;
  images?: VariantImage[];
  price: VariantPrice;
  productId: string;
  sku?: ProductVariantSkuKey;
  title: string;
  updatedAt: string;
}

export interface VariantPrice {
  amount: number;
  currency?: string;
  taxIncluded?: boolean;
  taxable?: boolean;
}

export interface VariantImage {
  id: string;
  type: string;
  url: string;
}

export interface VariantCustomFields {
  buildingArea?: string;
  category?: string;
  comment?: string;
  landArea?: string;
  price?: string;
}

export type ProductVariantCreate = Omit<
  ProductVariant,
  'id' | 'productId' | 'images' | 'createdAt' | 'updatedAt'
> & {
  imageIds?: string[];
};
export type ProductVariantsCreate = ProductVariantCreate & {
  productId: string;
};

export type ProductVariantUpdate = ProductVariantCreate;
export interface ProductVariantsUpdate {
  data: ProductVariantUpdate;
  ids: string[];
}

export interface ProductVariantsDelete {
  ids: string[];
}

export const ProductVariantSku = {
  AROUND: 'around', // 周辺環境
  EQUIPMENT: 'equipment', // 設備
  EXTERIOR: 'exterior', // 外観
  FLOOR_PLAN: 'floorPlan', // 間取り
  INTERIOR: 'interior', // 内観
  PLOT_MAP: 'plotMap', // 区画図
} as const;
export type ProductVariantSkuKey =
  (typeof ProductVariantSku)[keyof typeof ProductVariantSku];

/**
 * Attribute
 */
export interface Attribute {
  attributeId?: string;
  categoryIds: string[];
  children?: Attribute[];
  customFields?: AttributeCustomFields;
  groupName: string;
  id: string;
  items: AttributeItem[];
  name: string;
  order: number;
  type: string;
}

export interface AttributeCustomFields {
  icon?: {
    type: string;
    value: string;
  };
  keywords?: string;
}

export interface AttributeItem {
  groupName: string;
  key: string;
  value: string;
}

/**
 * Category
 */
export interface Category {
  children: Category[];
  description: string;
  id?: string;
  name: CategoryNameKey;
  numberOfProducts?: number;
  parent: null | {
    uri: string;
  };
  status: CategoryStatusKey;
}
export interface SubCategory {
  description: string;
  id?: string;
  name: string;
  numberOfProducts?: number;
  status: string;
}
export type CategoryInput = Omit<Category, 'id'>;

export const CategoryStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;
export type CategoryStatusKey =
  (typeof CategoryStatus)[keyof typeof CategoryStatus];

export const CategoryName = {
  EXHIBITION: 'exhibition',
  PROPERTY: 'property',
} as const;
export type CategoryNameKey = (typeof CategoryName)[keyof typeof CategoryName];

/**
 * Location
 */

export const ProductLocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;
export type ProductLocationTypeKey =
  (typeof ProductLocationType)[keyof typeof ProductLocationType];

export interface LocationListResponse {
  '@nextLink': string;
  count: number;
  total: number;
  value: Array<Location>;
}

export interface Location {
  code: string;
  createdAt: string;
  id: string;
  name: string;
  parent: string;
  type: LocationTypeKey;
  updatedAt: string;
}

export const LocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;
export type LocationTypeKey = (typeof LocationType)[keyof typeof LocationType];

export const ProductType = {
  REGULAR_PART_TIME: 'regularPartTime',
  SPOT: 'spot',
} as const;
export type ProductTypeKey = (typeof ProductType)[keyof typeof ProductType];

export const AttributeGroupName = {
  CLINICAL_DEPARTMENT: 'clinicalDepartment',
  JOB_TYPE: 'jobType',
} as const;
export type AttributeGroupNameKey =
  (typeof AttributeGroupName)[keyof typeof AttributeGroupName];

/**
 * attachment
 */
export interface Attachment {
  id: string;
  isPublic: boolean;
  ownerId: string;
  ownerType: AttachmentOwnerTypeKey;
  type: string;
  url: string;
}

export interface AttachmentCreate {
  objectId: string;
  ownerId?: string;
  type: string; //ファイル形式
}

export const AttachmentOwnerType = {
  ORGANIZATION: 'organization',
} as const;
export type AttachmentOwnerTypeKey =
  (typeof AttachmentOwnerType)[keyof typeof AttachmentOwnerType];
