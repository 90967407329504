import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { addDays, format, subDays } from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { getCsvFile } from '@app/adapter/order-service';
import { DatePicker } from '@app/components/Shared/Inputs/DatePicker';
import { SimpleDialog } from '@app/components/Shared/SimpleDialog';
import { useOrganization } from '@app/hooks/useOrganization';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  OrderCsvDownloadForm,
  OrderCsvDownloadFormKey,
} from '@app/schemas/order';
import { ProductCategory, ProductCategoryKey } from '@app/types/catalog';
import { Common } from '@app/types/common';
import { OrderStatus, OrderStatusKey, OrderTypeKey } from '@app/types/order';
import { isError } from '@app/utils/error';
import { getOrderTypeByProductCategory } from '@app/utils/order';

const CATEGORY_OPTIONS = [
  { label: 'すべて', value: Common.EMPTY },
  { label: '物件', value: ProductCategory.PROPERTY },
  { label: '展示場', value: ProductCategory.EXHIBITION },
] as const;
const STATUS_OPTIONS = [
  { label: '未対応', value: OrderStatus.PENDING },
  { label: '対応中（未選択・承認・否認）', value: OrderStatus.PROCESSING },
] as const;

interface OrderCsvDownloadButtonProps {
  label?: string;
  orderType?: OrderTypeKey;
  organizationId?: string;
  productCategory?: ProductCategoryKey;
  status?: OrderStatusKey;
}

export function OrderCsvDownloadButton({
  label = 'CSV',
  orderType,
  organizationId,
  productCategory,
  status,
}: OrderCsvDownloadButtonProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const [isShowDialog, setShowDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { fetchParentOrganizations, parentOrgs } = useOrganization();

  const {
    control,
    formState: { isValid },
    getValues,
    setValue,
  } = useForm<OrderCsvDownloadFormKey>(OrderCsvDownloadForm);
  const formWatch = useWatch({ control });

  useEffect(() => {
    void fetchParentOrganizations({ isAll: true });
  }, [fetchParentOrganizations]);

  useEffect(() => {
    if (organizationId) {
      setValue('organizationId', organizationId);
    }
    if (productCategory) {
      setValue('productCategory', productCategory);
    }
    if (status) {
      setValue('status', status);
    }
  }, [organizationId, productCategory, status, setValue]);

  const handleClickCsvDownload = async () => {
    try {
      setLoading(true);
      const values = getValues();
      const { data } = await getCsvFile({
        createdAt: [values.dateFrom, values.dateTo],
        orderType: getOrderTypeByProductCategory(
          values.productCategory || undefined,
          orderType
        ),
        orgId: organizationId || values.organizationId,
        productCategory: values.productCategory as ProductCategoryKey,
        status:
          values.status === OrderStatus.PROCESSING
            ? [
                OrderStatus.PROCESSING,
                OrderStatus.ACCEPTED,
                OrderStatus.CANCELED,
              ]
            : values.status,
      });
      if (!data) {
        throw new Error('CSVファイル情報が取得できませんでした');
      }

      const fileName = `${
        productCategory === ProductCategory.EXHIBITION ? '展示場' : '物件'
      }反響リスト_${format(new Date(), 'yyyyMMddHHmmss')}`;

      const link = document.createElement('a');
      link.href = data;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(data);
    } catch (e) {
      setSnackbar(true, 'ダウンロードに失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={() => setShowDialog(true)}
      >
        {label}
      </Button>
      <SimpleDialog
        open={isShowDialog}
        title="反響CSVダウンロード"
        content={
          <Stack spacing={4}>
            <FormControl>
              <FormLabel focused={false}>掲載</FormLabel>
              <Controller
                name="productCategory"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    {CATEGORY_OPTIONS.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={
                          <Typography variant="body2">
                            {option.label}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
            {!organizationId && (
              <FormControl>
                <FormLabel focused={false} className="required-label">
                  企業
                </FormLabel>
                <Controller
                  name="organizationId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      size="small"
                      displayEmpty
                      fullWidth
                      renderValue={(value: string) => {
                        if (value)
                          return (
                            parentOrgs.find((o) => o.id === value)?.name || ''
                          );
                        return (
                          <Typography variant="body2" color="secondary">
                            企業選択
                          </Typography>
                        );
                      }}
                      sx={{ '.MuiSelect-select': { py: 1.25 } }}
                    >
                      {parentOrgs.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            )}
            <Stack>
              <FormLabel focused={false} className="required-label">
                期間
              </FormLabel>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                divider={<Typography>〜</Typography>}
              >
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      inputRef={field.ref}
                      error={!!error}
                      datePicker={{
                        maxDate: new Date(formWatch.dateTo || '2099-12-31'),
                        minDate: subDays(
                          new Date(formWatch.dateTo || '1900-01-01'),
                          30
                        ),
                        value: field.value,
                      }}
                      actions={['clear']}
                      readOnly
                    />
                  )}
                />
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      inputRef={field.ref}
                      error={!!error}
                      datePicker={{
                        maxDate: addDays(
                          new Date(formWatch.dateFrom || '2099-12-31'),
                          30
                        ),
                        minDate: new Date(formWatch.dateFrom || '1900-01-01'),
                        value: field.value,
                      }}
                      actions={['clear']}
                      readOnly
                    />
                  )}
                />
              </Stack>
              <FormHelperText>
                ※最大1カ月（31日分）指定ができます
              </FormHelperText>
            </Stack>
            <FormControl>
              <FormLabel focused={false}>ステータス</FormLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    {STATUS_OPTIONS.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={
                          <Typography variant="body2">
                            {option.label}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
        }
        submitButton="出力"
        loading={isLoading}
        disabled={!isValid}
        fullWidth
        onClickSubmit={handleClickCsvDownload}
        onClickCancel={() => setShowDialog(false)}
      />
    </div>
  );
}
