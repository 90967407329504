import { Button, Card, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BasicInfoFormSection } from '@app/components/Catalog/Exhibition/BasicInfoFormSection';
import { FloorPlanFormSection } from '@app/components/Catalog/Exhibition/FloorPlanFormSection';
import { OverviewFormSection } from '@app/components/Catalog/Exhibition/OverviewFormSection';
import { MainVisualFormSection } from '@app/components/Catalog/MainVisualFormSection';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { isLoading } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import { useProduct } from '@app/hooks/useProduct';
import { useProductExhibitionForm } from '@app/hooks/useProductExhibitionForm';
import { useRoleChecker } from '@app/hooks/useRoleChecker';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { ProductCategory, ProductStatus } from '@app/types/catalog';
import { isError } from '@app/utils/error';

export function ExhibitionCreateEdit() {
  const organizationState = useRecoilValue(organizationSelector);
  const { checkWriteProduct } = useRoleChecker();
  if (!organizationState?.id) {
    throw new Error('所属情報が見つかりません');
  }

  const navigate = useNavigate();
  const { productId } = useParams();
  const setSnackbar = useSetSnackbar();
  const setLoadingState = useSetRecoilState(isLoading);
  const [isDraftValid, setDraftValid] = useState(false);
  const { fetchProduct, product } = useProduct(productId);
  const { productForm, sendFormValues, setFormValues, setOrganizationId } =
    useProductExhibitionForm();

  const {
    formState: { isValid },
    trigger,
  } = productForm;

  useEffect(() => {
    const execute = async () => {
      if (!productId) return;

      setLoadingState(true);
      const data = await fetchProduct(productId);
      if (data) {
        setDraftValid(!!data.name);
        setFormValues(data);
        void trigger;
      }
      setLoadingState(false);
    };
    setOrganizationId(organizationState.id);
    void execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const handlePreview = async () => {
    const latest = await handleDraftSubmit();
    if (latest?.id) {
      const url = `/preview/exhibitions/${latest.id}`;
      window.open(url, '_blank', 'noopener noreferrer');
    }
  };

  const handleDraftSubmit = async () => {
    try {
      setLoadingState(true);
      productForm.setValue('publication.status', ProductStatus.DRAFT);
      const latest = await sendFormValues(product?.id);
      setSnackbar(true, '下書き保存しました', 'success');
      return latest;
    } catch (error) {
      setSnackbar(
        true,
        '下書き保存に失敗しました。再度保存をお試しください',
        'error'
      );
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setLoadingState(false);
    }
  };

  const handleSubmitForm = async () => {
    try {
      setLoadingState(true);
      productForm.setValue('publication.status', ProductStatus.ACTIVE);
      await sendFormValues(product?.id);
      setSnackbar(true, '保存しました', 'success');
      navigate('/exhibitions/products');
    } catch (error) {
      setSnackbar(
        true,
        '保存に失敗しました。再度保存をお試しください',
        'error'
      );
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setLoadingState(false);
    }
  };

  if (!checkWriteProduct(ProductCategory.EXHIBITION)) {
    return <></>;
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <PageTitle
          title={`住宅展示場${productId ? '編集' : '登録'}`}
          buttonHidden
        />
        <div>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disabled={!isDraftValid}
            onClick={handlePreview}
          >
            <Typography>下書き保存してプレビュー</Typography>
          </Button>
        </div>
      </Stack>
      <Card sx={{ p: 4 }}>
        <FormProvider {...productForm}>
          <form>
            <Stack spacing={4} divider={<Divider />}>
              <OverviewFormSection
                onChangeName={(value) => setDraftValid(value !== '')}
              />
              <MainVisualFormSection />
              <FloorPlanFormSection />
              <BasicInfoFormSection />
            </Stack>
          </form>
        </FormProvider>
      </Card>
      <BottomMenu
        content={
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            width={1}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(-1)}
              sx={{ width: 106 }}
            >
              キャンセル
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={!isDraftValid}
              onClick={handleDraftSubmit}
              sx={{ width: 75 }}
            >
              下書き
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!isValid}
              onClick={() => handleSubmitForm()}
              sx={{ width: 140 }}
            >
              保存する(公開)
            </Button>
          </Stack>
        }
      ></BottomMenu>
    </>
  );
}
