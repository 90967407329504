import {
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
  ArrowBackIosRounded as ArrowBackIosRoundedIcon,
  AutorenewRounded as AutorenewRoundedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardMedia,
  Chip,
  IconButton,
  MenuItem,
  MobileStepper,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';

import { updateProduct } from '@app/adapter/catalog-service';
import {
  STATUS_OPTIONS,
  PROPERTY_STATUS_OPTIONS,
  ProductStatusLabel,
} from '@app/components/Catalog/ProductStatusLabel';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { theme } from '@app/theme';
import {
  Product,
  ProductImage,
  ProductUpdate,
  ProductStatusKey,
  ProductCategory,
} from '@app/types/catalog';
import {
  checkExpiration7DaysAgo,
  checkExpiration1DayAgo,
  checkExpirationAfter,
  checkUpdated4DaysLater,
  checkUpdated7DaysLater,
  checkUpdated8DaysLater,
} from '@app/utils/catalog';
import { isError } from '@app/utils/error';
import { dateConvertToFormat } from '@app/utils/format';

export interface ProductCardProps {
  onChange?: (product: Product) => void;
  onChangeStatus?: (status: ProductStatusKey, product: Product) => void;
  onClick?: (product: Product) => void;
  product: Product;
  readOnly?: boolean;
}
export function ProductCard({
  onChange,
  onChangeStatus,
  onClick,
  product,
  readOnly = false,
}: ProductCardProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const addressFull: string = [
    product.addressLine1 || '',
    product.addressLine2 || '',
    product.addressLine3 || '',
    product.customFields?.addressLine4 || '',
  ]
    .filter((i) => !!i)
    .join('');
  const statusOption = (
    product.customFields?.category === ProductCategory.PROPERTY
      ? PROPERTY_STATUS_OPTIONS
      : STATUS_OPTIONS
  ).find(({ value }) => value === product.publication.status);
  const isProperty: boolean =
    product.customFields?.category === ProductCategory.PROPERTY;
  const images: ProductImage[] = product.images || [];
  const [imageStep, setImageStep] = useState(0);

  // エラーアラート判定
  const [isUpdate8DaysError, setUpdate8DaysError] = useState(false);
  const [isUpdate7DaysError, setUpdate7DaysError] = useState(false);
  const [isUpdate4DaysError, setUpdate4DaysError] = useState(false);
  const [isExpiration7DaysError, setExpiration7DaysError] = useState(false);
  const [isExpirationTodayError, setExpirationTodayError] = useState(false);
  const [isExpirationAfterError, setExpirationAfterError] = useState(false);
  useEffect(() => {
    setUpdate4DaysError(checkUpdated4DaysLater(product));
    setUpdate7DaysError(checkUpdated7DaysLater(product));
    setUpdate8DaysError(checkUpdated8DaysLater(product));
    setExpiration7DaysError(checkExpiration7DaysAgo(product));
    setExpirationTodayError(checkExpiration1DayAgo(product));
    setExpirationAfterError(checkExpirationAfter(product));
  }, [product]);

  // 最終更新日アラート判定
  const isUpdateErrorAlert: boolean = useMemo(() => {
    return isUpdate8DaysError || isUpdate7DaysError || isUpdate4DaysError;
  }, [isUpdate8DaysError, isUpdate7DaysError, isUpdate4DaysError]);

  // 取引有効期限アラート判定
  const isExpirationErrorAlert: boolean = useMemo(() => {
    return (
      isExpiration7DaysError || isExpirationTodayError || isExpirationAfterError
    );
  }, [isExpiration7DaysError, isExpirationTodayError, isExpirationAfterError]);

  // 全アラート判定
  const isErrorAlert: boolean = useMemo(() => {
    return (isUpdateErrorAlert || isExpirationErrorAlert) && isProperty;
  }, [isUpdateErrorAlert, isExpirationErrorAlert, isProperty]);

  const handleUpdateStatus = async (status: ProductStatusKey) => {
    onChangeStatus?.(status, product);
  };

  const handleUpdatedAt = async () => {
    await handleProductUpdate({});
  };

  const handleProductUpdate = async (data: ProductUpdate) => {
    if (!data) return;
    setIsUpdating(true);
    try {
      const result = await updateProduct(product.id, data);
      onChange?.(result.data);
      setSnackbar(true, '更新しました', 'success');
    } catch (e) {
      setSnackbar(true, '更新に失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card>
      <CardMedia
        image={images[imageStep]?.url || ''}
        children={
          <Stack height={1} justifyContent="space-between">
            <Box>
              <Chip
                size="small"
                color={isErrorAlert ? 'error' : 'default'}
                icon={<CalendarMonthOutlinedIcon sx={{ width: 16 }} />}
                label={
                  <Typography variant="body3">
                    更新日：{dateConvertToFormat(product.updatedAt)}
                  </Typography>
                }
                sx={{
                  color: 'white',
                  cursor: 'inherit',
                  pl: 0.5,
                  ...(!isErrorAlert && {
                    backdropFilter: 'blur(4px)',
                    backgroundColor: 'rgba(51,51,51,0.4)',
                  }),
                }}
              />
            </Box>
            {!!images.length && (
              <Box textAlign="center">
                <MobileStepper
                  steps={images.length}
                  position="static"
                  activeStep={imageStep}
                  nextButton={
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setImageStep((prev) =>
                          imageStep < images.length - 1 ? prev + 1 : 0
                        );
                      }}
                      sx={{ ml: 1.5 }}
                    >
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  }
                  backButton={
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setImageStep((prev) =>
                          imageStep > 0 ? prev - 1 : images.length - 1
                        );
                      }}
                      sx={{ mr: 1.5 }}
                    >
                      <ArrowBackIosRoundedIcon />
                    </IconButton>
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              </Box>
            )}
          </Stack>
        }
        onClick={() => readOnly || onClick?.(product)}
        sx={{
          cursor: readOnly ? 'inherit' : 'pointer',
          height: 240,
          p: 2,
          pb: 1.5,
        }}
      />
      <Box
        p={1.5}
        sx={{
          backgroundColor: isErrorAlert ? theme.palette.error.light : 'inherit',
        }}
      >
        <Stack spacing={1.5}>
          <Typography fontWeight={700} className="text-ellipsis-line-1">
            {product.name}
          </Typography>
          <div>
            {addressFull && (
              <Typography
                variant="body2"
                color={theme.customPalette.gray8}
                className="text-ellipsis"
              >
                {addressFull}
              </Typography>
            )}
            <Typography
              variant="body2"
              color={theme.customPalette.gray8}
              className="text-ellipsis"
            >
              {product.id}
            </Typography>
          </div>
          <Paper variant="outlined" sx={{ fontSize: '0.875rem' }}>
            <Alert
              icon={false}
              severity={(statusOption?.severity as AlertColor) || 'info'}
              action={
                <Select
                  value={product.publication.status}
                  size="small"
                  disabled={isUpdating}
                  readOnly={readOnly}
                  className="border-none"
                  renderValue={() => (
                    <ProductStatusLabel variant="body2" product={product} />
                  )}
                  onChange={(e) =>
                    handleUpdateStatus(e.target.value as ProductStatusKey)
                  }
                >
                  {(product.customFields?.category === ProductCategory.PROPERTY
                    ? PROPERTY_STATUS_OPTIONS
                    : STATUS_OPTIONS
                  ).map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            >
              <Typography variant="body2" color={theme.customPalette.gray8}>
                掲載指示
              </Typography>
            </Alert>
            {product.customFields?.category === ProductCategory.PROPERTY && (
              <Stack
                spacing={3}
                p={1.5}
                sx={{ color: theme.customPalette.gray8 }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Box>取引有効期限</Box>
                  <Box fontWeight={700}>
                    {dateConvertToFormat(
                      product.customFields?.transactionExpirationDate
                    )}
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Box>価格</Box>
                  <Box fontWeight={700}>
                    {Number(
                      product.customFields?.minPrice || 0
                    ).toLocaleString()}
                    万円
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Box>残販売戸数</Box>
                  <Box fontWeight={700}>
                    {Number(
                      product.customFields?.salesHouseCount || 0
                    ).toLocaleString()}
                    戸
                  </Box>
                </Stack>
              </Stack>
            )}
          </Paper>
        </Stack>
        {isErrorAlert && (
          <Box mt={1.5}>
            <Stack spacing={1.5}>
              {isUpdateErrorAlert && (
                <>
                  <Typography variant="body2" color="error">
                    最終更新日から
                    {isUpdate8DaysError ? 8 : isUpdate7DaysError ? 7 : 4}
                    日が経過したので、
                    {isUpdate8DaysError
                      ? '掲載が終了しました。'
                      : 'まもなく掲載が終了します。'}
                  </Typography>
                  {!readOnly && (
                    <div>
                      <LoadingButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        loading={isUpdating}
                        startIcon={<AutorenewRoundedIcon />}
                        onClick={() => handleUpdatedAt()}
                        sx={{ height: 32 }}
                      >
                        更新する
                      </LoadingButton>
                    </div>
                  )}
                </>
              )}
              {isExpirationErrorAlert && (
                <Typography variant="body2" color="error">
                  取引有効期限切れ
                  {!isExpirationAfterError &&
                    (isExpirationTodayError
                      ? '1日前'
                      : isExpiration7DaysError && '7日前')}
                  となったので、
                  {isExpirationAfterError
                    ? '掲載が終了しました。'
                    : 'まもなく掲載が終了します。'}
                </Typography>
              )}
            </Stack>
          </Box>
        )}
      </Box>
    </Card>
  );
}
