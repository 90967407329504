import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import { get } from 'lodash';
import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { DecimalPointSeparate } from '@app/components/Shared/Inputs/DecimalPointSeparate';
import { ProductAreaType, ProductLandRightType } from '@app/types/catalog';
import { Common } from '@app/types/common';
import {
  PROPERTY_BUILDING_PERMIT_REASON_TYPE_VALUES,
  RANGE_TYPE_OPTIONS,
} from '@app/utils/constants';

const BUILDING_PERMIT_REASON_TYPE_OPTIONS = Object.entries(
  PROPERTY_BUILDING_PERMIT_REASON_TYPE_VALUES
).map(([value, label]) => {
  return { label, value };
});
const LAND_RIGHT_TYPE_OPTIONS = [
  { label: '所有権', value: ProductLandRightType.OWNERSHIP },
  { label: '旧法地上', value: ProductLandRightType.OLD_LAW_GROUND },
  { label: '旧法賃借', value: ProductLandRightType.OLD_LAW_LEASE },
  { label: '普通地上', value: ProductLandRightType.REGULAR_GROUND },
  { label: '定期地上', value: ProductLandRightType.FIXED_GROUND },
  { label: '普通賃借', value: ProductLandRightType.REGULAR_LEASE },
  { label: '定期賃借', value: ProductLandRightType.FIXED_LEASE },
];
const LAND_AREA_TYPE_OPTIONS = [
  { label: '登記', value: ProductAreaType.REGISTRY },
  { label: '実測', value: ProductAreaType.SURVEY },
  { label: '未選択', value: Common.EMPTY },
];
const LOAD_PAINTING_OPTIONS = [
  '無舗装',
  'アスファルト舗装',
  'コンクリート舗装',
  'インターロック舗装',
];
const USE_DISTRICT_OPTIONS = [
  '１種低層',
  '２種低層',
  '１種中高',
  '２種中高',
  '１種住居',
  '２種住居',
  '準住居',
  '田園住居',
  '近隣商業',
  '商業',
  '準工業',
  '工業',
  '工業専用',
  '無指定',
  '市街化調整区域',
  '都市計画区域外',
];

export function LandInfoFormSection(): ReactElement {
  const {
    clearErrors,
    control,
    formState: { errors },
    resetField,
  } = useFormContext();
  const landRightType = useWatch({ control, name: 'landRightType' });
  const useDistrict = useWatch({ control, name: 'useDistrict' });
  const useDistricts = useWatch({ control, name: 'useDistricts' });

  const isUseDistrictException = useMemo(
    () =>
      useDistrict === '市街化調整区域' ||
      useDistricts.includes('市街化調整区域'),
    [useDistrict, useDistricts]
  );

  useEffect(() => {
    if (!isUseDistrictException) {
      clearErrors('buildingPermitReasonType');
      resetField('buildingPermitReasonType', { defaultValue: '' });
    }
  }, [isUseDistrictException, clearErrors, resetField]);

  return (
    <Stack spacing={3}>
      <Typography variant="h5">土地情報</Typography>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          土地の権利形態
        </FormLabel>
        <Controller
          name="landRightType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Select
                {...field}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value)
                    return (
                      LAND_RIGHT_TYPE_OPTIONS.find((o) => o.value === value)
                        ?.label || ''
                    );
                  return (
                    <Typography variant="body2" color="secondary">
                      選択してください
                    </Typography>
                  );
                }}
                onChange={(e) => {
                  resetField('landLeaseholdDuration', { defaultValue: '' });
                  resetField('landLeaseholdRent', { defaultValue: '' });
                  resetField('landLeaseholdCost', { defaultValue: '' });
                  field.onChange(e.target.value);
                }}
              >
                {LAND_RIGHT_TYPE_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}{' '}
            </div>
          )}
        />
      </FormControl>
      {!!landRightType && landRightType !== ProductLandRightType.OWNERSHIP && (
        <>
          <FormControl fullWidth>
            <FormLabel focused={false} className="required-label">
              借地権の場合、その期間等
            </FormLabel>
            <Controller
              name="landLeaseholdDuration"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel focused={false} className="required-label">
              借地権の場合その月賃料
            </FormLabel>
            <Controller
              name="landLeaseholdRent"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel focused={false}>借地権の場合その他の費用</FormLabel>
            <Controller
              name="landLeaseholdCost"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </>
      )}
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          土地面積
        </FormLabel>
        <Controller
          name="landAreaType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup {...field} row>
                {LAND_AREA_TYPE_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
        <Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <DecimalPointSeparate
              field1stName="minLandArea.0"
              field2ndName="minLandArea.1"
              hideErrorMessage
            />
            <Typography>㎡</Typography>
            <Controller
              name="landAreaRangeType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  error={!!error}
                  displayEmpty
                  renderValue={(value: string) => {
                    if (value)
                      return (
                        RANGE_TYPE_OPTIONS.find((o) => o.value === value)
                          ?.label || ''
                      );
                    return (
                      <Typography variant="body2" color="secondary">
                        選択してください
                      </Typography>
                    );
                  }}
                >
                  <MenuItem value="">{'　'}</MenuItem>
                  {RANGE_TYPE_OPTIONS.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <DecimalPointSeparate
              field1stName="maxLandArea.0"
              field2ndName="maxLandArea.1"
              hideErrorMessage
            />
            <Typography>㎡</Typography>
          </Stack>
          {Array.from(
            new Set([
              get(errors, 'minLandArea.0.message', ''),
              get(errors, 'minLandArea.1.message', ''),
              get(errors, 'maxLandArea.0.message', ''),
              get(errors, 'maxLandArea.1.message', ''),
              get(errors, 'landAreaRangeType.message', ''),
            ])
          )
            .filter((m) => !!m)
            .map((message, index) => (
              <FormHelperText key={index} error>
                {message as string}
              </FormHelperText>
            ))}
          <Controller
            name="tsuboNotationFlag"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                {...field}
                checked={field.value}
                label={
                  <Typography variant="body2">
                    面積の坪表記を行う（※建物面積にも適用されます）
                  </Typography>
                }
                control={<Checkbox />}
                sx={{ ml: 0, width: 'fit-content' }}
              />
            )}
          />
          <FormHelperText>
            ※ 私道面積は土地面積に含めないで下さい
            <br />
            {`※ 私道、セットバック、傾斜部分、路地状部分、地役権等の必要表示項目がある場合には、道路欄に記入して下さい`}
            <br />
            {`※ 高圧線下部等の必要表示項目がある場合には、その他制限欄に記入して下さい`}
          </FormHelperText>
        </Stack>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>建ペイ率・容積率</FormLabel>
        <Controller
          name="areaRatio"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>
          ※ 「都市計画区域外」以外は必須
          <br />※ 建ペイ率：40%、容積率：80％
        </FormHelperText>
      </FormControl>
      <Typography fontWeight={700}>道路</Typography>
      <Stack>
        <FormLabel focused={false} className="condition-required-label">
          道路幅
        </FormLabel>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <DecimalPointSeparate
            field1stName="minRoadWidth.0"
            field2ndName="minRoadWidth.1"
            hideErrorMessage
          />
          <Typography>m</Typography>
          <Controller
            name="roadWidthRangeType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value)
                    return (
                      RANGE_TYPE_OPTIONS.find((o) => o.value === value)
                        ?.label || ''
                    );
                  return (
                    <Typography variant="body2" color="secondary">
                      選択してください
                    </Typography>
                  );
                }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {RANGE_TYPE_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <DecimalPointSeparate
            field1stName="maxRoadWidth.0"
            field2ndName="maxRoadWidth.1"
            hideErrorMessage
          />
          <Typography>m</Typography>
        </Stack>
        {Array.from(
          new Set([
            get(errors, 'minRoadWidth.0.message', ''),
            get(errors, 'minRoadWidth.1.message', ''),
            get(errors, 'maxRoadWidth.0.message', ''),
            get(errors, 'maxRoadWidth.1.message', ''),
            get(errors, 'roadWidthRangeType.message', ''),
          ])
        )
          .filter((m) => !!m)
          .map((message, index) => (
            <FormHelperText key={index} error>
              {message as string}
            </FormHelperText>
          ))}
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          舗装
        </FormLabel>
        <Controller
          name="roadPainting"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Select
                {...field}
                error={!!error}
                displayEmpty
                sx={{ width: 182 }}
              >
                <MenuItem value="">未選択</MenuItem>
                {LOAD_PAINTING_OPTIONS.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          その他
        </FormLabel>
        <Controller
          name="roadNote"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>
          ※ 建築基準法の道路ではない「通路」はその他制限事項に記入して下さい
          <br />
          {`※ 私道、セットバック、傾斜部分、路地状部分、地役権等の必要表示項目がある場合には必記入`}
          <br />
          例：セットバック：要0.55m2~0.6m2（一部）、路地状部分：34％~42%含
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          設備
        </FormLabel>
        <Controller
          name="facility"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例：○○電力、公営水道、本下水、都市ガス</FormHelperText>
      </FormControl>
      <Stack>
        <FormLabel focused={false} className="required-label">
          用途地域
        </FormLabel>
        <Controller
          name="useDistrict"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Select
                {...field}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value) return value;
                  return (
                    <Typography variant="body2" color="secondary">
                      選択してください
                    </Typography>
                  );
                }}
                sx={{ width: 182 }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {USE_DISTRICT_OPTIONS.map((value, index) => (
                  <MenuItem
                    key={index}
                    value={value}
                    disabled={useDistricts.includes(value)}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
        <FormHelperText sx={{ my: 1 }}>
          ※ 複数の用途にまたがる場合は以下に指定して下さい
        </FormHelperText>
        <Stack direction="row" spacing={1}>
          {Array.from({ length: 3 }, (h, index) => (
            <Controller
              key={index}
              name={`useDistricts.${index}`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  error={!!error}
                  displayEmpty
                  renderValue={(value: string) => {
                    if (value) return value;
                    return (
                      <Typography variant="body2" color="secondary">
                        選択してください
                      </Typography>
                    );
                  }}
                  sx={{ width: 182 }}
                >
                  <MenuItem value="">{'　'}</MenuItem>
                  {USE_DISTRICT_OPTIONS.map((value, index) => (
                    <MenuItem
                      key={index}
                      value={value}
                      disabled={
                        useDistricts.includes(value) || useDistrict === value
                      }
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          ))}
        </Stack>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false}>地目</FormLabel>
        <Controller
          name="landCategory"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例）宅地、田、畑、山林、雑種地、原野</FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          建築許可理由
        </FormLabel>
        <Controller
          name="buildingPermitReasonType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box mb={1}>
              <Select
                {...field}
                error={!!error}
                displayEmpty
                disabled={!isUseDistrictException}
                renderValue={(value: string) => {
                  if (value)
                    return (
                      BUILDING_PERMIT_REASON_TYPE_OPTIONS.find(
                        (o) => o.value === value
                      )?.label || ''
                    );
                  return (
                    <Typography variant="body2" color="secondary">
                      選択してください
                    </Typography>
                  );
                }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {BUILDING_PERMIT_REASON_TYPE_OPTIONS.map(
                  ({ value, label }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </Box>
          )}
        />
        <FormHelperText>
          ※用途地域が市街化調区域内の場合必須
          <br />
          「建物許可要」を選択できるのは、各行政の開発基準に該当し、確実に建築できる場合です。
          <br />
          尚、住宅の建築ができないものや、分家住宅のような一身専属的な権利でのみ建築が認められるものは掲載できません。
        </FormHelperText>
      </FormControl>
    </Stack>
  );
}
