import Typography, { TypographyProps } from '@mui/material/Typography';
import { ReactElement } from 'react';

import { theme } from '@app/theme';
import { Product, ProductCategory, ProductStatus } from '@app/types/catalog';

// 物件のステータス
export const PROPERTY_STATUS_OPTIONS: {
  color: string;
  label: string;
  severity: string;
  value: string;
}[] = [
  {
    color: theme.customPalette.green,
    label: '掲載',
    severity: 'success',
    value: ProductStatus.ACTIVE,
  },
  {
    color: 'error',
    label: '掲載停止',
    severity: 'error',
    value: ProductStatus.DRAFT,
  },
  {
    color: theme.customPalette.black,
    label: '抹消予定',
    severity: 'info',
    value: ProductStatus.ARCHIVED,
  },
];
// 物件以外のステータス
export const STATUS_OPTIONS: {
  color: string;
  label: string;
  severity: string;
  value: string;
}[] = [
  {
    color: theme.customPalette.green,
    label: '掲載',
    severity: 'success',
    value: ProductStatus.ACTIVE,
  },
  {
    color: 'error',
    label: '掲載停止',
    severity: 'error',
    value: ProductStatus.DRAFT,
  },
  {
    color: theme.customPalette.black,
    label: '抹消予定',
    severity: 'info',
    value: ProductStatus.ARCHIVED,
  },
];

export interface ProductStatusLabelProps extends TypographyProps {
  product?: Product;
}
export function ProductStatusLabel({
  product,
  ...props
}: ProductStatusLabelProps): ReactElement {
  const { color, label } =
    (product?.customFields?.category === ProductCategory.PROPERTY
      ? PROPERTY_STATUS_OPTIONS
      : STATUS_OPTIONS
    ).find(({ value }) => value === product?.publication.status) || {};
  return (
    <Typography color={color || 'inherit'} {...props}>
      {label || ''}
    </Typography>
  );
}
