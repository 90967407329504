import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { NumericTextField } from '@app/components/Shared/Inputs/NumericTextField';
import { ProductTransportMean } from '@app/types/catalog';

const TRANSPORT_MEAN_OPTIONS = [
  { label: '徒歩', value: ProductTransportMean.WALK },
  { label: '車', value: ProductTransportMean.CAR },
  { label: 'バス', value: ProductTransportMean.BUS },
];

export interface Props {
  disabled?: boolean;
  fieldName: {
    line: string;
    mean: string;
    nearest: string;
    note: string;
    timeMax: string;
    timeMin: string;
  };
}

export function TransportFormSection({
  fieldName,
  disabled = false,
}: Props): ReactElement {
  const { clearErrors, control, setValue } = useFormContext();
  const mean = useWatch({ control, name: fieldName.mean });

  // 範囲入力（最小〜最大）制限
  const isTimeInput = useMemo(() => {
    return (
      mean === ProductTransportMean.WALK || mean === ProductTransportMean.CAR
    );
  }, [mean]);

  return (
    <>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          路線(バス会社)
        </FormLabel>
        <Controller
          name={fieldName.line}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              disabled={disabled}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          駅(バス停)
        </FormLabel>
        <Controller
          name={fieldName.nearest}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              disabled={disabled}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FormControl>
      <Stack>
        <FormLabel focused={false} className="required-label">
          駅(バス停)まで
        </FormLabel>
        <Controller
          name={fieldName.mean}
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              onChange={(e) => {
                field.onChange(e);
                setValue(fieldName.note, '');
                setValue(fieldName.timeMin, '');
                setValue(fieldName.timeMax, '');
                clearErrors([
                  fieldName.timeMin,
                  fieldName.timeMax,
                  fieldName.note,
                ]);
              }}
            >
              {TRANSPORT_MEAN_OPTIONS.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio disabled={disabled} />}
                  label={
                    <Typography variant="body2">{option.label}</Typography>
                  }
                />
              ))}
            </RadioGroup>
          )}
        />
        {isTimeInput ? (
          <Stack direction="row" spacing={1.5}>
            <Stack direction="row" spacing={0.5}>
              <Controller
                name={fieldName.timeMin}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    maxLength={3}
                    error={!!error}
                    helperText={error?.message}
                    disabled={disabled}
                    sx={{ width: 100 }}
                  />
                )}
              />
              <Typography pt={1}>
                {mean === ProductTransportMean.CAR ? 'km' : '分'}
              </Typography>
            </Stack>
            <Typography pt={1}>〜</Typography>
            <Stack direction="row" spacing={0.5}>
              <Controller
                name={fieldName.timeMax}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    maxLength={3}
                    error={!!error}
                    helperText={error?.message}
                    disabled={disabled}
                    sx={{ width: 100 }}
                  />
                )}
              />
              <Typography pt={1}>
                {mean === ProductTransportMean.CAR ? 'km' : '分'}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          mean === ProductTransportMean.BUS && (
            <FormControl fullWidth>
              <Controller
                name={fieldName.note}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    disabled={disabled}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <FormHelperText>
                例）〇〇バスで◇分。〇〇バス停までは徒歩◇分。
              </FormHelperText>
            </FormControl>
          )
        )}
      </Stack>
    </>
  );
}
