import { RemoveCircleOutlined as RemoveCircleOutlinedIcon } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ReactElement, useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Link } from 'react-router-dom';

import { AdditionalButton } from '@app/components/Shared/Button/AdditionalButton';
import { theme } from '@app/theme';
import {
  ProductPropertyCategory,
  ProductSalesStatus,
  ProductSalesStatusKey,
} from '@app/types/catalog';

const PROPERTY_CATEGORY_OPTIONS = [
  { label: '一戸建て', value: ProductPropertyCategory.DETACHED_HOUSE },
];
const SALES_STATUS_OPTIONS = [
  { label: '販売予定', value: ProductSalesStatus.SCHEDULED_SALE },
  { label: '新規分譲', value: ProductSalesStatus.NEW_BUILDING_CONDO },
  { label: '分譲中', value: ProductSalesStatus.USED_CONDO },
  { label: '完売・成約', value: ProductSalesStatus.SOLD_OUT },
  { label: '売り止め', value: ProductSalesStatus.SELLING_STOP },
  { label: '掲載止め', value: ProductSalesStatus.SECRET },
];

export interface OverviewFormSectionProps {
  onChangeName?: (value: string) => void;
  onChangeSalesStatus?: (value: ProductSalesStatusKey) => void;
}
export function OverviewFormSection({
  onChangeName,
  onChangeSalesStatus,
}: OverviewFormSectionProps): ReactElement {
  const {
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext();
  const salesStatus = useWatch({ control, name: 'salesStatus' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'features',
  });
  const regulationFilePath = `${window.location.origin}/ホムホム売買掲載規定書.pdf`;

  useEffect(() => {
    if (salesStatus !== ProductSalesStatus.SCHEDULED_SALE) {
      clearErrors('previewAdComment');
    }
  }, [salesStatus, clearErrors]);

  return (
    <Stack spacing={3}>
      <Typography variant="h5">概要</Typography>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          種別
        </FormLabel>
        <Controller
          name="propertyCategory"
          control={control}
          render={({ field }) => (
            <>
              <RadioGroup {...field} row>
                {PROPERTY_CATEGORY_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {errors.propertyCategory && (
                <FormHelperText error>
                  {errors.propertyCategory.message as string}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          販売状態
        </FormLabel>
        <Controller
          name="salesStatus"
          control={control}
          render={({ field }) => (
            <>
              <RadioGroup
                {...field}
                row
                onChange={(e) => {
                  field.onChange(e);
                  onChangeSalesStatus?.(
                    e.target.value as ProductSalesStatusKey
                  );
                }}
              >
                {SALES_STATUS_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {errors.salesStatus && (
                <FormHelperText error>
                  {errors.salesStatus.message as string}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel
          focused={false}
          className={
            salesStatus === ProductSalesStatus.SCHEDULED_SALE
              ? 'required-label'
              : 'condition-required-label'
          }
        >
          予告広告催促コメント
        </FormLabel>
        <Controller
          name="previewAdComment"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="予告広告催促コメントを入力"
            />
          )}
        />

        <Typography variant="body3" mt={1} color={'secondary'}>
          ※記入例は
          <Link
            style={{ color: theme.palette.secondary.main }}
            to={regulationFilePath}
            target="_blank"
          >
            売買掲載規定書
          </Link>
          の「予告広告の必掲コメント」項目をご参照ください。
        </Typography>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          物件名
        </FormLabel>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="物件名を入力"
              onChange={(e) => {
                field.onChange(e);
                onChangeName?.(e.target.value);
              }}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>物件の特徴</FormLabel>
        <Stack spacing={1}>
          {fields.map((field, index) => (
            <FormControl key={field.id}>
              <Stack direction="row" alignItems="center">
                <Controller
                  name={`features.${index}.value`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      placeholder="特徴を入力"
                      fullWidth
                    />
                  )}
                />
                {!!index && (
                  <IconButton color="error" onClick={() => remove(index)}>
                    <RemoveCircleOutlinedIcon />
                  </IconButton>
                )}
              </Stack>
            </FormControl>
          ))}
          <AdditionalButton
            label="特徴の追加"
            disabled={fields.length >= 7}
            fullWidth
            onClick={() => append({ value: '' })}
          />
        </Stack>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          メインキャッチ
        </FormLabel>
        <Controller
          name="additionalInformation"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="メインキャッチを入力"
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          サブキャッチ
        </FormLabel>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              rows={5}
              error={!!error}
              helperText={error?.message}
              placeholder="サブキャッチを入力"
              multiline
            />
          )}
        />
        <FormHelperText>
          例）ご家庭のライフスタイルに寄り添う、世界でいちばん幸せな「わが家」をご提案
        </FormHelperText>
      </FormControl>
    </Stack>
  );
}
