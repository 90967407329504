import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';

export interface NumericTextFieldProps
  extends Omit<TextFieldProps, 'onChange' | 'value'> {
  maxLength?: number;
  onChange?: (value: string) => void;
  value?: string;
}

export const NumericTextField = forwardRef(function (
  { maxLength, value, onChange, ...textFieldProps }: NumericTextFieldProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = event.target.value;
    const sanitizedValue = inputValue
      .replace(/[０-９]/g, (char) => {
        return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
      })
      .replace(/[^0-9]/g, '');

    // 小数点チェック
    const dots = sanitizedValue.split('.');
    if (dots.length > 2) {
      return;
    }
    // 文字数のチェック
    if (maxLength !== undefined && sanitizedValue.length > maxLength) return;

    onChange?.(sanitizedValue);
  };

  return (
    <TextField
      ref={ref as ForwardedRef<HTMLDivElement>}
      value={value}
      onChange={handleChange}
      inputProps={{
        inputMode: 'numeric',
        maxLength,
        pattern: '[0-9]*',
      }}
      {...textFieldProps}
    />
  );
});
